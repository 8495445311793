import React, { useEffect, useRef, useState } from "react";
import { Link, useParams } from "react-router-dom";
import Checkbox from "@mui/material/Checkbox";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftInput from "components/SoftInput";
import SoftButton from "components/SoftButton";
import { Box, Card, Grid } from "@mui/material";
import ApiClient from "Services/ApiClient";
import { useSoftUIController } from "context";
import { startLoading } from "context";
import { setLoading } from "context";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { toast } from "react-toastify";
import { createQuerry } from "Services/endpointes";
import { setDialog } from "context";
import { getQuerryById } from "Services/endpointes";
import { setQuerry } from "context";
import querryView from "./data";
import Table from "examples/Tables/Table";
import DefaultInfoCard from "examples/Cards/InfoCards/DefaultInfoCard";
import Separator from "layouts/authentication/components/Separator";

function SupportTicket() {
  const form = useRef(null);
  const [controller, dispatch] = useSoftUIController();
  const { user, querry } = controller;
  const { step } = useParams();
  const [errors, setErrors] = useState({});

  const getAllQuerrys = async () => {
    startLoading(dispatch, true);
    try {
      const response = await ApiClient.getDataByParam(getQuerryById, user.id.toLowerCase());
      if (response.status == 200) {
        setQuerry(dispatch, response.data);
        console.log(querry, "queeererrrry");
      } else {
        setDialog(dispatch, [response]);
      }
    } catch (error) {
      toast.info(error.toString());
      setLoading(dispatch, false);
    }
  };

  const validateForm = (formData) => {
    const newErrors = {};
    for (let [key, value] of formData.entries()) {
      if (!value.trim()) {
        newErrors[key] = "This field is required";
      }
      if (key === "phone" && value.trim().length !== 10) {
        newErrors[key] = "Phone number must be exactly 10 digits";
      }
    }
    return newErrors;
  };

  const submitHandler = async (e) => {
    e.preventDefault();
    const formData = new FormData(form.current);
    const formErrors = validateForm(formData);

    formData.append("id", user.id);
    if (Object.keys(formErrors).length > 0) {
      setErrors(formErrors);
      return;
    }

    for (let [key, value] of formData.entries()) {
      console.log(key, value, user.id);
    }

    startLoading(dispatch, true);
    try {
      const response = await ApiClient.createData(createQuerry, formData);
      console.log(response);
      if (response.status === 200) {
        form.current.reset();
      }
      setDialog(dispatch, [response]);
    } catch (error) {
      setLoading(dispatch, false);
      toast.error(error.toString());
    }
  };
  let memoizedRowsSupport = querryView.rows(querry, dispatch);
  useEffect(() => {
    getAllQuerrys();
  }, []);
  return (
    <DashboardLayout>
      <DashboardNavbar call={getAllQuerrys} />
      <SoftBox p={3}>
        <SoftBox display="flex" alignItems="center" justifyContent="center">
          <Card>
            <SoftBox pt={3} px={2}>
              <SoftBox
                component="form"
                role="form"
                onSubmit={(e) => submitHandler(e)}
                textAlign="center"
                encType="multipart/form-data"
                alignItems="center"
                ref={form}
              >
                <SoftBox mb={1}>
                  <SoftTypography variant="h5" fontWeight="bold" textGradient>
                    Feel Free to Reachout to Us
                  </SoftTypography>
                </SoftBox>

                <SoftBox mb={2} width="100%">
                  <SoftInput name="fullName" placeholder="Name" />
                  {errors.fullName && (
                    <SoftTypography color="error" fontSize="0.8rem">
                      {errors.fullName}
                    </SoftTypography>
                  )}
                </SoftBox>

                <SoftBox mb={2} width="100%">
                  <SoftInput
                    name="phone"
                    type="text"
                    placeholder="Phone"
                    onChange={(e) => {
                      e.target.value.replace();
                      e.target.value =
                        e.target.value.length > 10
                          ? e.target.value.toString().substr(0, 10)
                          : e.target.value;
                      if (isNaN(e.key)) {
                        e.preventDefault();
                      }
                    }}
                    max={10}
                    onKeyPress={(e) => {
                      if (isNaN(e.key)) {
                        e.preventDefault();
                      }
                    }}
                  />
                  {errors.phone && (
                    <SoftTypography color="error" fontSize="0.8rem">
                      {errors.phone}
                    </SoftTypography>
                  )}
                  <SoftTypography color="text" fontWeight="light" fontSize="0.8rem">
                    Please enter the Valid Mobile Number
                  </SoftTypography>
                </SoftBox>
                <SoftBox mb={2} width="100%">
                  <SoftInput name="query" type="text" placeholder="Your querry" />
                  {errors.query && (
                    <SoftTypography color="error" fontSize="0.8rem">
                      {errors.query}
                    </SoftTypography>
                  )}
                </SoftBox>
                <SoftBox mt={1} mb={1}>
                  <SoftButton variant="gradient" type="submit" color="info">
                    Submit
                  </SoftButton>
                </SoftBox>
                <Separator />
                <SoftTypography fontSize="small" pb={2}>
                  {" "}
                  Call us : <Link href="tel:9875982205">9875982205</Link>
                </SoftTypography>
              </SoftBox>
            </SoftBox>
          </Card>
        </SoftBox>
        <SoftBox py={3} pt={0} mt={4}>
          <SoftBox mb={3}>
            <Card>
              <SoftBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
                <SoftTypography variant="h6">My Querry</SoftTypography>
              </SoftBox>

              {querry?.length > 0 ? (
                <Box>
                  <Table columns={querryView.columns} rows={memoizedRowsSupport} />
                </Box>
              ) : (
                <SoftBox mt={4}>
                  <SoftBox mb={1.5}>
                    <Grid container spacing={3}>
                      <Grid item lg={12}>
                        <Grid item container spacing={3}>
                          <Grid item xs={12} xl={12}>
                            <DefaultInfoCard
                              icon="cloud"
                              title={`You Don't have an active achievement yet. Add connection to your portfolio and start earning.`}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </SoftBox>
                </SoftBox>
              )}
            </Card>
          </SoftBox>
        </SoftBox>
      </SoftBox>
    </DashboardLayout>
  );
}
export default SupportTicket;
