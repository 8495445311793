// class ConnectionsModel {
//   constructor(
//     id,
//     userId,
//     tnxId,
//     invoiceNo,
//     storage,
//     amount,
//     totalPayment,
//     tax,
//     status,
//     isAlloted,
//     storageId,
//     url,
//     transactionId,
//     paymentMethod,
//     serialNo,
//     createdAt
//   ) {
//     this.id = id;
//     this.userId = userId;
//     this.tnxId = tnxId;
//     this.invoiceNo = invoiceNo;
//     this.storage = storage;
//     this.amount = amount;
//     this.totalPayment = totalPayment;
//     this.tax = tax;
//     this.status = status;
//     this.isAlloted = isAlloted;
//     this.storageId = storageId;
//     this.url = url;
//     this.transactionId = transactionId;
//     this.paymentMethod = paymentMethod;
//     this.serialNo = serialNo;
//     this.createdAt = createdAt;
//   }

//   fromJson(jsonData) {
//     return new ConnectionsModel(
//       jsonData._id ?? null,
//       jsonData.userId,
//       jsonData.tnxId,
//       jsonData.invoiceNo,
//       jsonData.storage ?? 0,
//       jsonData.amount ?? 0,
//       jsonData.totalPayment ?? 0,
//       jsonData.tax ?? 18.0,
//       jsonData.status ?? false,
//       jsonData.isAlloted ?? false,
//       jsonData.storageId ?? "",
//       jsonData.url ?? "",
//       jsonData.transactionId ?? null,
//       jsonData.paymentMethod ?? null,
//       jsonData.serialNo ?? "",
//       jsonData.createdAt ?? new Date()
//     );
//   }

//   fromArray(jsonData) {
//     let data = [];
//     for (let json of jsonData) {
//       data.push(
//         new ConnectionsModel(
//           json._id ?? null,
//           json.userId,
//           json.tnxId,
//           json.invoiceNo,
//           json.storage ?? 0,
//           json.amount ?? 0,
//           json.totalPayment ?? 0,
//           json.tax ?? 18.0,
//           json.status ?? false,
//           json.isAlloted ?? false,
//           json.storageId ?? "",
//           json.url ?? "",
//           json.transactionId ?? null,
//           json.paymentMethod ?? null,
//           json.serialNo ?? "",
//           json.createdAt ?? new Date()
//         )
//       );
//     }
//     return data;
//   }
// }

// export default ConnectionsModel;

class ConnectionsModel {
  constructor(
    id,
    userId,
    storage,
    amount,
    tax,
    status,
    isAlloted,
    storageId,
    url,
    transactionId,
    createdAt,
    updatedAt
  ) {
    this.id = id;
    this.userId = userId;
    this.storage = storage;
    this.amount = amount;
    this.tax = tax;
    this.status = status;
    this.isAlloted = isAlloted;
    this.storageId = storageId;
    this.url = url;
    this.transactionId = transactionId;
    this.createdAt = createdAt;
    this.updatedAt = updatedAt;
  }

  fromJson(jsonData) {
    return new ConnectionsModel(
      jsonData._id ?? null,
      jsonData.userId,
      jsonData.storage ?? 0,
      jsonData.amount ?? 0,
      jsonData.tax ?? 18.0,
      jsonData.status ?? false,
      jsonData.isAlloted ?? false,
      jsonData.storageId ?? "",
      jsonData.url ?? "",
      jsonData.transactionId ?? null,
      jsonData.createdAt ?? new Date(),
      jsonData.updatedAt ?? new Date()
    );
  }

  fromArray(jsonData) {
    return jsonData.map((json) => this.fromJson(json));
  }
}

export default ConnectionsModel;
