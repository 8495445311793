import * as React from "react";
import { PropTypes } from "prop-types";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";

export default function NewFormDialog({ open, setOpen, data }) {
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <React.Fragment>
      {data.status == "dialog" ? (
        <Dialog
          open={open}
          onClose={handleClose}
          PaperProps={{
            style: {
              borderRadius: "50px",
            },
            component: "form",

            onClick: (e) => {
              e.preventDefault();
              const formData = new FormData(e.currentTarget);
              data.call(formData);
            },
          }}
        >
          {data?.children}
        </Dialog>
      ) : (
        <Dialog
          open={open}
          onClose={handleClose}
          PaperProps={{
            component: "form",
            onSubmit: (e) => {
              e.preventDefault();
              const formData = new FormData(e.currentTarget);
              data.call(formData);
            },
          }}
        >
          {data?.title && <DialogTitle>{data?.title}</DialogTitle>}
          <DialogContent px={data.message ? 4 : 0}>
            <DialogTitle fontSize={14} whiteSpace={"nowrap"}>
              {data?.message}
            </DialogTitle>
            {data?.children}
          </DialogContent>

          <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
            {data.action && <Button type="submit">{data?.action}</Button>}
          </DialogActions>
        </Dialog>
      )}
    </React.Fragment>
  );
}

NewFormDialog.defaultProps = {
  open: false,
  setOpen: () => {},
  data: [] ?? "",
};

NewFormDialog.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  data: PropTypes.any,
};
