// @mui material components
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import Card from "@mui/material/Card";

// Next Work Dashboard React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

// Next Work Dashboard React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import MiniStatisticsCard from "examples/Cards/StatisticsCards/MiniStatisticsCard";

// Next Work Dashboard React base styles
import typography from "assets/theme/base/typography";

// Dashboard layout components
import { useSoftUIController } from "context";

// Data
import reportsBarChartData from "layouts/dashboard/data/reportsBarChartData";
import WorkWithTheRockets from "./components/WorkWithTheRockets";
import OrdersOverview from "./components/OrderOverview";
import Table from "examples/Tables/Table";
import MyTeamView from "./data/team";
import { NavLink } from "react-router-dom";
import SoftButton from "components/SoftButton";
import { toast } from "react-toastify";
import { setDialog } from "context";
import ApiClient from "Services/ApiClient";
import { getUserRewards } from "Services/endpointes";
import { startLoading } from "context";
import { useEffect } from "react";
import { setLoading } from "context";
import { claimReward } from "Services/endpointes";
import { setRewards } from "context";
import CountdownTimer from "./components/RewardPopup/popup";

// import Countdown from "components/Countdown";

function Dashboard() {
  const [controller, dispatch] = useSoftUIController();
  const { user, member, directMember } = controller;
  const { size } = typography;
  const { chart } = reportsBarChartData;
  // const targetDate = new Date("2024-12-31T23:59:59");

  const miniStatisticsData = [
    {
      title: { text: "My Storage" },
      count: user?.ownStr ?? 0,
      percentage: { color: "success" },
      icon: { color: "info", component: "storage" },
    },

    {
      title: { text: "My Earnings" },
      count: user?.earning ?? 0,
      percentage: { color: "error" },
      icon: { color: "info", component: "currency_rupee" },
    },
    {
      title: { text: "My Team size" },
      count: member.length ?? 0,
      percentage: { color: "success" },
      icon: { color: "info", component: "groups" },
    },
    {
      title: { text: "Team Storage" },
      count: user.memStr ?? 0,
      percentage: { color: "error" },
      icon: { color: "info", component: "storage" },
    },
  ];

  let memoizedRows = MyTeamView.rows(directMember, dispatch, user);
  const getAllRewards = async () => {
    startLoading(dispatch, true);
    try {
      const response = await ApiClient.getData(getUserRewards);
      if (response.status == 200) {
        setRewards(dispatch, response.data);
        if (response.reward?._id) {
          setDialog(dispatch, [
            {
              call: () => {
                claimRewards(response.reward?._id);
              },
              status: "dialog",
              action: "Check eligibility",
              children: (
                <CountdownTimer data={response.reward} user={user} />
                // <Box>
                //   <Box display="flex" justifyContent={"space-between"} py={1} pr={2}>
                //     <Typography variant="button" fontWeight="bold" textTransform="capitalize">
                //       Reward
                //     </Typography>
                //     <Typography variant="button" fontWeight="regular" color="text">
                //       {response.reward?.reward}
                //     </Typography>
                //   </Box>
                //   <Box display="flex" justifyContent={"space-between"} py={1} pr={2}>
                //     <Typography variant="button" fontWeight="bold" textTransform="capitalize">
                //       Time Limit
                //     </Typography>
                //     <Typography variant="button" fontWeight="regular" color="text">
                //       {response.reward?.rule} Days
                //     </Typography>
                //   </Box>
                //   <Box display="flex" justifyContent={"space-between"} py={1} pr={2}>
                //     <Typography variant="button" fontWeight="bold" textTransform="capitalize">
                //       Required Business
                //     </Typography>
                //     <Typography variant="button" fontWeight="regular" color="text">
                //       {response.reward?.range} TB
                //     </Typography>
                //   </Box>
                // </Box>
              ),
            },
          ]);
        }
      } else {
        setDialog(dispatch, [response]);
      }
    } catch (error) {
      setLoading(dispatch, false);
      // toast.info(response.message);
    }
  };
  const claimRewards = async (id) => {
    startLoading(dispatch, true);
    try {
      const response = await ApiClient.createData(claimReward + `/${id}`);

      setDialog(dispatch, [response]);
    } catch (error) {
      setLoading(dispatch, false);
      toast.info(error.response);
    }
  };
  useEffect(() => {
    getAllRewards();
  }, []);
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SoftBox py={3}>
        <SoftBox mb={3}>
          <Grid container spacing={2}>
            {miniStatisticsData.map((data, index) => (
              <Grid key={index} item xs={12} sm={6} lg={3}>
                <MiniStatisticsCard {...data} />
              </Grid>
            ))}
          </Grid>
        </SoftBox>
        <SoftBox mb={3}>
          <Grid container spacing={3}></Grid>
        </SoftBox>

        <Grid container spacing={3}>
          <Grid item xs={12} md={12} lg={7}>
            <Card>
              <SoftBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
                <SoftBox>
                  <SoftTypography variant="h6" gutterBottom>
                    My Direct Team
                  </SoftTypography>
                  <SoftBox display="flex" alignItems="center" lineHeight={0}>
                    <Icon
                      sx={{
                        fontWeight: "bold",
                        color: ({ palette: { info } }) => info.main,
                        mt: -0.5,
                      }}
                    >
                      done
                    </Icon>
                    <SoftTypography variant="button" fontWeight="regular" color="text">
                      &nbsp;<strong> {directMember.length ?? 0} Direct Team</strong>
                    </SoftTypography>
                  </SoftBox>
                </SoftBox>
                <NavLink to="/my-team">
                  {" "}
                  <SoftButton variant="gradient" color="dark" ml={2}>
                    &nbsp;View More
                  </SoftButton>
                </NavLink>
              </SoftBox>
              <Table columns={MyTeamView.columns} rows={memoizedRows} />
            </Card>
          </Grid>
          <Grid item xs={12} lg={5}>
            <Grid item xs={12} lg={12} mb={3}>
              <WorkWithTheRockets />
            </Grid>
            <Grid item xs={12} md={12} lg={12}>
              <OrdersOverview />
            </Grid>
          </Grid>
        </Grid>
      </SoftBox>
    </DashboardLayout>
  );
}

export default Dashboard;
