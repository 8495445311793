/* eslint-disable react/prop-types */
import React, { useEffect, useRef, useState } from "react";
import "./style.css";

const CountdownTimer = ({ data, user }) => {
  const [modalOpen, setModalOpen] = useState(false);
  const daysRef = useRef(null);
  const hoursRef = useRef(null);
  const minutesRef = useRef(null);
  const secondsRef = useRef(null);

  const calculateTimeLeft = () => {
    const createdDate = new Date(user.createdAt);
    const targetDate = new Date(createdDate);
    targetDate.setDate(createdDate.getDate() + data.range);
    const now = new Date();
    const difference = targetDate - now;
    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      };
    } else {
      timeLeft = { days: 0, hours: 0, minutes: 0, seconds: 0 };
    }

    return timeLeft;
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);
    return () => clearInterval(timer);
  }, [data]);

  const openModal = () => setModalOpen(true);
  const closeModal = () => setModalOpen(false);

  useEffect(() => {
    const modal = document.getElementById("myModal");
    const span = document.getElementsByClassName("close")[0];

    const handleWindowClick = (event) => {
      if (event.target === modal) {
        modal.style.display = "none";
      }
    };

    span.onclick = () => {
      modal.style.display = "none";
    };

    window.onclick = handleWindowClick;

    return () => {
      window.onclick = null;
    };
  }, []);

  return (
    <div className="countdown-timer">
      <main>
        <div id="myModal" className="modal">
          <div className="modal-content">
            <p className="close" onClick={closeModal}></p>
            <div className="container">
              <div className="award-head">
                <h5>You Can Achieve This Award</h5>
              </div>
              <div className="countdown">
                <div className="main-countdown">
                  <div className="cards">
                    <div className="card days" ref={daysRef}>
                      <div className="flip-card">
                        <div className="top-half">{timeLeft.days}</div>
                        <div className="bottom-half">{timeLeft.days}</div>
                      </div>
                      <p>Days</p>
                    </div>
                    <div className="card hours" ref={hoursRef}>
                      <div className="flip-card">
                        <div className="top-half">{timeLeft.hours}</div>
                        <div className="bottom-half">{timeLeft.hours}</div>
                      </div>
                      <p>Hours</p>
                    </div>
                    <div className="card minutes" ref={minutesRef}>
                      <div className="flip-card">
                        <div className="top-half">{timeLeft.minutes}</div>
                        <div className="bottom-half">{timeLeft.minutes}</div>
                      </div>
                      <p>Minutes</p>
                    </div>
                    <div className="card seconds" ref={secondsRef}>
                      <div className="flip-card">
                        <div className="top-half">{timeLeft.seconds}</div>
                        <div className="bottom-half">{timeLeft.seconds}</div>
                      </div>
                      <p>Seconds</p>
                    </div>
                  </div>
                  <div className="awards-critera">
                    <img src="./awards.png" alt="Awards" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default CountdownTimer;
