import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import PropTypes from "prop-types";
import SoftBox from "components/SoftBox";
import ivancik from "assets/images/cloud-bg.png";
import { useSoftUIController, setLayout } from "context";
import Footer from "examples/Footer";

function DashboardLayout({ children }) {
  const [controller, dispatch] = useSoftUIController();
  const { miniSidenav } = controller;
  const { pathname } = useLocation();

  useEffect(() => {
    setLayout(dispatch, "dashboard");
  }, [pathname]);

  return (
    <>
      <SoftBox
        sx={({ breakpoints, transitions, functions: { pxToRem, rgba } }) => ({
          p: 3,
          position: "relative",
          backgroundSize: "cover",
          backgroundImage: ({ functions: { linearGradient }, palette: { gradients } }) =>
            ` ${linearGradient(
              rgba(gradients.secondary.main, 0.3),
              rgba(gradients.secondary.state, 0.3)
            )},url(${ivancik})`,
          height: "100%",
          backgroundRepeat: "no-repeat",
          backgroundColor: rgba("#fff", 0.5), // Set the background color with opacity (0.5 for example)
          [breakpoints.up("xl")]: {
            marginLeft: miniSidenav ? pxToRem(120) : pxToRem(274),
            transition: transitions.create(["margin-left", "margin-right"], {
              easing: transitions.easing.easeInOut,
              duration: transitions.duration.standard,
            }),
          },
        })}
      >
        {children}
        <Footer />
      </SoftBox>
    </>
  );
}

// Typechecking props for the DashboardLayout
DashboardLayout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default DashboardLayout;
