import React, { useState, useEffect, Suspense } from "react";
import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import themeRTL from "assets/theme/theme-rtl";
import routes from "routes";
import brand from "assets/images/logo-ct.png";
import {
  useSoftUIController,
  setUser,
  setMiniSidenav,
  startLoading,
  setDialog,
  setNotification,
  setLoading,
} from "context";
import Sidenav from "examples/Sidenav";
import Loading from "layouts/loading";
import ApiClient from "Services/ApiClient";
import { ToastContainer, toast } from "react-toastify";
import { getUserById, getUserNotification, achieve } from "Services/endpointes";
import { setMembers } from "context";
import { getMembers } from "Services/endpointes";

export default function App() {
  const [controller, dispatch] = useSoftUIController();
  const { miniSidenav, sidenavColor, user, loading } = controller;
  const [onMouseEnter, setOnMouseEnter] = useState(false);
  const { pathname } = useLocation();
  const [userLoaded, setUserLoaded] = useState(false);
  const [notificationsLoaded, setNotificationsLoaded] = useState(false);

  const handleOnMouseEnter = () => {
    if (miniSidenav && !onMouseEnter) {
      setMiniSidenav(dispatch, false);
      setOnMouseEnter(true);
    }
  };

  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      setMiniSidenav(dispatch, true);
      setOnMouseEnter(false);
    }
  };

  const getMember = async () => {
    startLoading(dispatch, true);
    try {
      const response = await ApiClient.getData(getMembers);
      if (response.status == 200) {
        setMembers(dispatch, response.data);
      } else {
        setDialog(dispatch, [response]);
      }
    } catch (error) {
      toast.info(error.message);
      setLoading(dispatch, false);
    }
  };

  function getCookie() {
    const cookieArray = document.cookie.split(";");
    let userId, authToken, expiryTime;
    for (let i = 0; i < cookieArray.length; i++) {
      const cookie = cookieArray[i].trim();
      if (cookie.startsWith("userId=")) {
        userId = cookie.substring("userId=".length);
      } else if (cookie.startsWith("authToken=")) {
        authToken = cookie.substring("authToken=".length);
      } else if (cookie.startsWith("expiryTime=")) {
        expiryTime = new Date(cookie.substring("expiryTime=".length));
      }
    }

    // Check if the cookies are expired
    if (expiryTime && new Date() > expiryTime) {
      deleteCookie("userId");
      deleteCookie("authToken");
      deleteCookie("expiryTime");
      return false;
    }

    if (userId && authToken && userId !== "") {
      return true;
    } else {
      return false;
    }
  }

  function deleteCookie(name) {
    document.cookie = name + "=; expires=Thu, 01 Jan 1970 00:00:01 GMT; path=/";
  }

  function setCookieWithExpiry(name, value, minutes) {
    const date = new Date();
    const expiryTime = new Date(date.getTime() + minutes * 60 * 1000).toISOString();
    document.cookie = name + "=" + value + ";path=/";
    document.cookie = "expiryTime=" + expiryTime + ";path=/";
  }

  const deleteData = () => {
    deleteCookie("userId");
    deleteCookie("authToken");
    deleteCookie("expiryTime");
    window.location.reload();
  };

  async function getUser() {
    try {
      startLoading(dispatch, true);
      const data = await ApiClient.getData(getUserById);
      if (data.status === 200) {
        setUser(dispatch, data?.data);
        getMember();
        setUserLoaded(true);
      } else {
        deleteData();
        setDialog(dispatch, [data]);
        setLoading(dispatch, false);
      }
    } catch (error) {
      deleteData();
      setLoading(dispatch, false);
      toast.success("Welcome To Nextwork Technologies!");
    }
  }

  async function getUserNotifications() {
    try {
      const notifications = await ApiClient.getData(getUserNotification);
      if (notifications.status === 200) {
        setNotification(dispatch, notifications.data);
        setNotificationsLoaded(true);
      } else {
        setNotificationsLoaded(false);
      }
    } catch (error) {
      setNotificationsLoaded(false);
    }
  }

  async function callAchieve() {
    try {
      const royality = await ApiClient.createData(achieve, "");
      if (royality.status === 200) {
        setDialog(dispatch, [royality]);
      }
    } catch (error) {
      console.error("Error calling achieve API", error);
    }
  }

  useEffect(() => {
    if (getCookie() && !user?.id) {
      getUser();
      getUserNotifications();
    }
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  useEffect(() => {
    if (userLoaded && notificationsLoaded) {
      callAchieve();
    }
  }, [userLoaded, notificationsLoaded]);

  return (
    <ThemeProvider theme={themeRTL}>
      <ToastContainer />
      <CssBaseline />
      <Loading condition={loading} />
      {getCookie() && user?.id ? (
        <>
          <Sidenav
            color={sidenavColor}
            brand={brand}
            routes={routes}
            onMouseEnter={handleOnMouseEnter}
            onMouseLeave={handleOnMouseLeave}
          />
        </>
      ) : null}
      <Routes>
        {routes?.map((route) => {
          if (getCookie() && user?.id) {
            if (route.auth !== null) {
              return (
                <Route
                  exact
                  path={route.route}
                  element={
                    <Suspense fallback={<Loading condition={true} />}>{route.component}</Suspense>
                  }
                  key={`${route.key}-${route.route}`}
                />
              );
            }
          } else if (route.auth === null) {
            return (
              <Route
                exact
                path={route.route}
                element={
                  <Suspense fallback={<Loading condition={true} />}>{route.component}</Suspense>
                }
                key={`${route.key}-${route.route}`}
              />
            );
          }
        })}

        {getCookie() && user?.id ? (
          <Route path="/*" element={<Navigate to="/dashboard" />} />
        ) : (
          <Route path="/*" element={<Navigate to="/" />} />
        )}
      </Routes>
    </ThemeProvider>
  );
}
