import { useState, useEffect, useRef } from "react";

// react-router components
import { Link, useLocation, useNavigate } from "react-router-dom";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @material-ui core components
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import Icon from "@mui/material/Icon";

// Next Work Dashboard React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

// Next Work Dashboard React examples
import Breadcrumbs from "examples/Breadcrumbs";

// Custom styles for DashboardNavbar
import {
  navbar,
  navbarContainer,
  navbarRow,
  navbarIconButton,
  navbarMobileMenu,
} from "examples/Navbars/DashboardNavbar/styles";

// Next Work Dashboard React context
import { useSoftUIController, setTransparentNavbar, setMiniSidenav } from "context";
import { setDialog } from "context";
import { Avatar, Box, Button, Divider, Grid, Stack, Typography } from "@mui/material";
import NotificationItem from "examples/Items/NotificationItem";
import SoftBadge from "components/SoftBadge";

function DashboardNavbar({ absolute, light, isMini, call }) {
  const [navbarType, setNavbarType] = useState();
  const [controller, dispatch] = useSoftUIController();
  const { miniSidenav, transparentNavbar, fixedNavbar } = controller;
  const [openMenu, setOpenMenu] = useState(false);
  const [openNotif, setOpenNotif] = useState(false);
  const route = useLocation().pathname.split("/").slice(1);
  const { user, notifications } = controller;
  const handleLogout = () => {
    const cookies = document.cookie.split(";");

    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i];
      const eqPos = cookie.indexOf("=");
      const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
      document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
    }
    window.location.reload();
  };
  const logoutHandler = () => {
    setDialog(dispatch, [
      {
        status: "form",
        action: "Logout",
        title: "Are you sure to Logout from your Account.",
        call: handleLogout,
      },
    ]);
  };
  useEffect(() => {
    if (fixedNavbar) {
      setNavbarType("sticky");
    } else {
      setNavbarType("static");
    }

    function handleTransparentNavbar() {
      setTransparentNavbar(dispatch, (fixedNavbar && window.scrollY === 0) || !fixedNavbar);
    }

    window.addEventListener("scroll", handleTransparentNavbar);

    handleTransparentNavbar();

    return () => window.removeEventListener("scroll", handleTransparentNavbar);
  }, [dispatch, fixedNavbar]);

  const handleMiniSidenav = () => setMiniSidenav(dispatch, !miniSidenav);
  const handleOpenMenu = (event) => setOpenMenu(event.currentTarget);
  const handleOpenNotif = (event) => setOpenNotif(event.currentTarget);
  const handleCloseMenu = () => setOpenMenu(false);
  const handleCloseNotif = () => setOpenNotif(false);

  const generateReferLink = (id) => {
    const referLink = window.location.origin;

    return `${referLink}/sign-up/1?sponsorId=${id}&placementId=${id}`;
  };

  function generateWhatsAppMessage(referralLink) {
    const companyName = "Nextwork Technologies Ltd";
    const message = `👋 Hey there!\n\nLooking to start earning?💰\nClick on this link to get started with ${companyName}.\n\nLink: ${referralLink}\n\nJoin us at ${companyName} and explore exciting opportunities to earn from the comfort of your home.\n\nHappy earning! 🚀\n\nRegards\n${user.fullName}`;
    return message;
  }

  const handleSend = (message) => {
    // Construct the WhatsApp URL with only the prewritten message
    const whatsappUrl = `https://wa.me/?text=${encodeURIComponent(message)}`;

    // Open WhatsApp in a new window or tab
    window.open(whatsappUrl, "_blank");
  };
  const handleCopyLink = (user) => {
    const referLink = generateReferLink(user.id);

    let message = generateWhatsAppMessage(referLink);
    setDialog(dispatch, [
      {
        status: "form",
        title: "Here is Your Refer Link",
        children: (
          <Grid container spacing={1} textAlign={"center"} alignItems={"center"}>
            <Grid item xs={1}>
              <Icon small>link</Icon>
            </Grid>
            <Grid item xs={11}>
              <Typography
                fontSize={13}
                whiteSpace={"nowrap"}
                onClick={() => {
                  navigator.clipboard
                    .writeText(referLink)
                    .then(() => {
                      setDialog(dispatch, [
                        {
                          status: 200,
                          message:
                            "Link has been coppied to clipboard. Please share the link with your new Member.",
                        },
                      ]);
                    })
                    .catch((_) => {
                      setDialog(dispatch, [{ status: 400, message: "Unable to copy the Link." }]);
                    });
                }}
              >
                <Button>Click TO Copy your Refer Link</Button>
              </Typography>
            </Grid>
          </Grid>
        ),
        action: "Share",
        call: () => handleSend(message),
      },
    ]);
  };

  const renderMenu = () => (
    <Menu
      anchorEl={openMenu}
      anchorReference={null}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      open={Boolean(openMenu)}
      onClose={handleCloseMenu}
    >
      <Box>
        <SoftBox
          variant="gradient"
          px={1}
          my={0.5}
          component={Link}
          to="/my-profile"
          display="flex"
          sx={{ cursor: "pointer" }}
          alignItems="center"
          color="warning"
        >
          <Divider />
          <Icon fontSize="1rem">account_circle</Icon>
          <SoftTypography cursor="pointer" fontSize="1rem" pl={1} component="span">
            My Profile
          </SoftTypography>
        </SoftBox>
        <SoftBox
          variant="gradient"
          px={1}
          my={0.5}
          component={Link}
          to="/create-members"
          display="flex"
          sx={{ cursor: "pointer" }}
          alignItems="center"
          color="secondary"
        >
          <Divider />
          <Icon fontSize="1rem">group_add</Icon>
          <SoftTypography cursor="pointer" fontSize="1rem" pl={1} component="span">
            Add Member
          </SoftTypography>
        </SoftBox>

        <SoftBox
          variant="gradient"
          px={1}
          my={0.5}
          onClick={() => handleCopyLink(user)}
          display="flex"
          alignItems="center"
          sx={{ cursor: "pointer" }}
          color="info"
        >
          <Divider />
          <Icon fontSize="1rem">share_icon</Icon>
          <SoftTypography cursor="pointer" fontSize="1rem" pl={1} component="span">
            Refer Link
          </SoftTypography>
        </SoftBox>
        <SoftBox
          variant="gradient"
          px={1}
          my={0.5}
          onClick={() => logoutHandler()}
          display="flex"
          sx={{ cursor: "pointer" }}
          alignItems="center"
          color="error"
        >
          <Divider />
          <Icon fontSize="1rem">logout_icon</Icon>
          <SoftTypography cursor="pointer" fontSize="1rem" pl={1} component="span">
            Log Out
          </SoftTypography>
        </SoftBox>
      </Box>
    </Menu>
  );
  const renderNotification = () => (
    <Menu
      anchorEl={openNotif}
      anchorReference={null}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      open={Boolean(openNotif)}
      onClose={handleCloseNotif}
    >
      <Box>
        {notifications?.length > 0 &&
          notifications
            ?.slice(0, 3)
            ?.map((e) => (
              <NotificationItem
                key={e._id}
                image={<Icon size="small">{e.icon}</Icon>}
                title={e.title}
              />
            ))}
      </Box>
    </Menu>
  );

  return (
    <AppBar
      position={absolute ? "absolute" : navbarType}
      color="dark"
      sx={(theme) => navbar(theme, { transparentNavbar, absolute, light })}
    >
      <Toolbar sx={(theme) => navbarContainer(theme)}>
        <SoftBox sx={(theme) => navbarRow(theme, { isMini })}>
          <SoftBox
            color={"dark"}
            mb={{ xs: 1, md: 0 }}
            sx={(theme) => navbarRow(theme, { isMini })}
          >
            <Breadcrumbs
              icon="home"
              call={call}
              title={route[route.length - 1]}
              route={route}
              light={light}
              color={"dark"}
            />
          </SoftBox>

          <SoftBox color={"dark"}>
            <IconButton
              color="dark"
              size="large"
              sx={navbarIconButton}
              aria-controls="notification-menu"
              aria-haspopup="true"
              variant="contained"
              onClick={handleOpenNotif}
            >
              <Icon className={light ? "text-white" : "text-dark"} color="white" fontSize="14px">
                notifications
              </Icon>
              <SoftBadge
                sx={{ position: "absolute", top: 0, left: 10 }}
                variant="gradient"
                color={"error"}
                size="8px"
                badgeContent={user.unread}
                circular
              />
            </IconButton>
            {renderNotification()}
          </SoftBox>

          <SoftBox color={light ? "white" : "inherit"}>
            <IconButton
              size="small"
              color="inherit"
              sx={navbarIconButton}
              aria-controls="Profile-menu"
              aria-haspopup="true"
              variant="contained"
              onClick={handleOpenMenu}
            >
              <Stack direction="row" spacing={2}>
                <Avatar alt="Remy Sharp" width="100%" src="51365.jpg" />
              </Stack>
            </IconButton>
            {renderMenu()}
          </SoftBox>

          <IconButton
            size="small"
            color="inherit"
            sx={navbarMobileMenu}
            onClick={handleMiniSidenav}
          >
            <Icon className={light ? "text-white" : "text-dark"}>
              {!miniSidenav ? "menu_open" : "menu"}
            </Icon>
          </IconButton>
        </SoftBox>
      </Toolbar>
    </AppBar>
  );
}

// Setting default values for the props of DashboardNavbar
DashboardNavbar.defaultProps = {
  absolute: false,
  light: false,
  isMini: false,
  call: null,
};

// Typechecking props for the DashboardNavbar
DashboardNavbar.propTypes = {
  absolute: PropTypes.bool,
  light: PropTypes.bool,
  isMini: PropTypes.bool,
  call: PropTypes.func,
};

export default DashboardNavbar;
