// class Transaction {
//     constructor(id,
//         amount,
//         type,
//         userId,
//         tnxId,
//         invoiceNo,
//         status,
//         paymentMethod,
//         createdAt,
//         updatedAt) {

//             this.id = id;
//             this.amount = amount;
//             this.type = type;
//             this.userId = userId;
//             this.tnxId = tnxId;
//             this.invoiceNo = invoiceNo;
//             this.status = status;
//             this.paymentMethod = paymentMethod;
//             this.createdAt = createdAt;
//             this.updatedAt = updatedAt;
//     }

//     fromArray(jsonData) {
//       let data = [];
//       for (let json of jsonData) {
//         data.push(
//           new Transaction(
//             json._id ?? null,
//             json.amount,
//             json.type,
//             json.userId,
//             json.tnxId,
//             json.invoiceNo,
//             json.status,
//             json.paymentMethod,
//             json.createdAt,
//             json.updatedAt
//           )
//         );
//       }
//       return data;
//     }
//   }

//   export default Transaction;

class TransactionModel {
  constructor(
    id,
    userId,
    amount,
    type,
    tnxId,
    invoiceNo,
    status,
    paymentMethod,
    createdAt,
    updatedAt
  ) {
    this.id = id;
    this.userId = userId;
    this.amount = amount;
    this.type = type;
    this.tnxId = tnxId;
    this.invoiceNo = invoiceNo;
    this.status = status;
    this.paymentMethod = paymentMethod;
    this.createdAt = createdAt;
    this.updatedAt = updatedAt;
  }

  fromJson(jsonData) {
    return new TransactionModel(
      jsonData._id ?? null,
      jsonData.userId,
      jsonData.amount ?? 0,
      jsonData.type ?? "",
      jsonData.tnxId ?? "",
      jsonData.invoiceNo ?? 0,
      jsonData.status ?? false,
      jsonData.paymentMethod ?? "",
      jsonData.createdAt ?? new Date(),
      jsonData.updatedAt ?? new Date()
    );
  }

  fromArray(jsonData) {
    return jsonData.map((json) => this.fromJson(json));
  }
}

export default TransactionModel;
